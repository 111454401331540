.heading {
    margin-bottom: 30px;
}

.tabs {
    margin-top: 40px;
}

.tabHeading {
    padding-bottom: 4px;
}

.tabContent {
    /*padding-top: 20px;*/
    padding-top: 1rem;
}

.flex {
    display: flex;
}

.tabContentTopBar {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.hallSelect {
    margin-right: 30px;
}

@media (max-width: 480px) {
    .tabContentTopBar {
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 18px;
    }
    .hallSelect {
        margin-right: 0;
        margin-bottom: 18px;
    }
}
