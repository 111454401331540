html {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

body {
    margin: 0;
    background: url("../assets/background.png") no-repeat fixed;
    color: #000000;
    font-family: "Proxima Nova Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
}
