@font-face {
    font-family: "Proxima Nova Regular";
    src: url("../assets/ProximaNova-Regular.eot");
    src:
        url("../assets/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"),
        url("../assets/ProximaNova-Regular.woff") format("woff"),
        url("../assets/ProximaNova-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova Bold";
    src: url("../assets/ProximaNova-Bold.eot");
    src:
        url("../assets/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/ProximaNova-Bold.woff") format("woff"),
        url("../assets/ProximaNova-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova Extra Bold";
    src: url("../assets/ProximaNova-Extrabld.eot");
    src:
        url("../assets/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"),
        url("../assets/ProximaNova-Extrabld.woff") format("woff"),
        url("../assets/ProximaNova-Extrabld.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
