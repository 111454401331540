:root {
    /* palette */
    --color-dark: #000000;
    --color-light: #ffffff;

    /* theme */
    --border-color: #f2f2f2;
    --pale-text-color: color(var(--color-dark) a(40%));

    /* typography */
    --font-family-regular: "Proxima Nova Regular", sans-serif;
    --font-family-bold: "Proxima Nova Bold", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: var(--font-family-bold);
    font-weight: 700;
}

h1 {
    font-size: 36px;
    line-height: 40px;
}

h3 {
    font-family: var(--font-family-regular);
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}

a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: inherit;
    text-decoration: none;

    &:hover {
        border-bottom-color: var(--color-dark);
    }
}

b,
strong {
    font-family: var(--font-family-bold);
    font-weight: 700;
}
